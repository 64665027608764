import React, { useEffect, useState } from "react";

import StrategySummaryStore from "../../Store/StrategySummary";
// import { AgGridReact } from "ag-grid-react"; // AG Grid Component
// import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
// import "ag-grid-community/styles/ag-theme-alpine.css";
import moneyFormatter from "../../AGCustomFilters/moneyFormatter";
import {
  Container,
  Box,
  Typography,
  Switch,
  FormControlLabel,
} from "@mui/material";
import useDevModeStore from "../../Store/DevModeStore"; // Import the Zustand store

export default function TotalPnLGrid({ devMode }) {
  const { isDevMode, setIsDevMode } = useDevModeStore(); // Use Zustand store

  const visibleRows = StrategySummaryStore((state) => state.visibleRows);
  const [dataList, setDataList] = useState([]);
  // const dashboardCellFontSize = "9px";
  // const redColor = "indianred";
  // const greenColor = "green";
  //   const orangeColor = "orange";
  useEffect(() => {
    let capturedPnl = 0;
    let currentPnl = 0;
    let netPnl = 0;
    let varDown = 0;
    let varUp = 0;
    visibleRows.forEach((item) => {
      capturedPnl += item["Cap P&L"];
      currentPnl += item["Curr P&L"];
      netPnl += item["Net P&L"];
      varDown += typeof item["varDown"] === "number" ? item["varDown"] : 0;
      varUp += typeof item["varUp"] === "number" ? item["varUp"] : 0;
    });

    setDataList([
      { label: "Captured P&L", value: moneyFormatter(capturedPnl, "₹") },
      { label: "Current P&L", value: moneyFormatter(currentPnl, "₹") },
      { label: "Net P&L", value: moneyFormatter(netPnl, "₹") },
      {
        label: "Value at Risk (Lakhs)",
        value: moneyFormatter(Math.min(varDown, varUp) / 100000),
      },
    ]);
  }, [visibleRows]);

  const handleDevModeChange = (event) => {
    setIsDevMode(event.target.checked);
  };

  return (
    <Container
      sx={{
        backgroundColor: "background.paper",
        padding: 1,
        borderRadius: 1,
        margin: 0,
      }}
    >
      <Box display="flex" alignItems="center" flexWrap="wrap">
        {dataList.map((item, index) => (
          <Box key={index} display="flex" alignItems="center" mx={1}>
            <Typography mr={1}>{item.label}:</Typography>
            <Box>
              <Typography>{item.value}</Typography>
            </Box>
          </Box>
        ))}
        <Box flexGrow={1} /> {/* This will take up the remaining space */}
        <FormControlLabel
          control={
            <Switch checked={isDevMode} onChange={handleDevModeChange} />
          }
          label="Dev"
          sx={{
            marginLeft: "auto", // Ensures it's pushed to the right
          }}
        />
      </Box>
    </Container>
  );
}
